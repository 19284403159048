import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IEvent } from "../../interfaces";

@Component({
	selector: "app-alerts",
	templateUrl: "./alerts.component.html",
	styleUrls: ["./alerts.component.scss"],
	standalone: false
})
export class AlertsComponent {
	@Input() event: IEvent;
	@Input() title: string;
	@Input() message: string;
	@Input() cancelBtn: string;
	@Input() confirmBtn: string;
	@Input() type: string = "ok";
	@Input() maxWidth: string = "100%";
	@Input() bgIconColor: string = "";
	@Input() iconColor: string = "";

	@Output() cancelAlert: EventEmitter<boolean> = new EventEmitter();
	@Output() confirmAlert: EventEmitter<boolean> = new EventEmitter();

	cancel() {
		this.cancelAlert.emit(true);
	}

	confirm() {
		this.confirmAlert.emit(true);
	}
}
