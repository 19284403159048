import { Component, inject, input, model } from "@angular/core";
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from "@ionic/core";
import { IEvent, IEventUser, IFullCustomField } from "src/app/shared/interfaces";
import { EventUsersService } from "src/app/shared/services";
import { LogFuncTrace } from "src/app/shared/utils-func/debug-decorators";
import * as _ from "lodash-es";

@Component({
	selector: "user-fields-visibility-manage",
	template: `<div class="tw-block tw-mx-auto tw-w-fit">
		<ion-checkbox
			[value]="eventUser().updatedSettings?.['fieldsVisibility']?.[fieldId()]"
			[checked]="eventUser().updatedSettings?.['fieldsVisibility']?.[fieldId()]"
			mode="ios"
			[style.color]="event().styling.contentTextColor"
			size="13px"
			justify="start"
			alignment="center"
			labelPlacement="end"
			(ionChange)="updateFieldVisibility(fieldId(), $event)"
		>
			{{ "labels.show_on_public_profile" | translate }}</ion-checkbox
		>
	</div>`,
	styles: `
		:host {
			width: 100%;
		}
		ion-checkbox {
			--size: 1rem;
			--border-radius: 5px;
			--checkmark-color: var(--btn-text-color);
			--checkbox-background-checked: var(--btn-bg-color);
			--label-padding: 0px !important;
			--transition: 0.5s;
			--margin-inline-start: -8px !important;
		}
	`,
	standalone: false
})
export class UserFieldsVisibilityManageComponent {
	SEventUsers = inject(EventUsersService);

	eventUser = model.required<IEventUser>();
	event = input.required<IEvent>();
	fieldId = input.required<string>();
	computedCustomFields = input.required<IFullCustomField[]>();

	/**
	 * updateFieldVisibility
	 * @param key
	 */
	@LogFuncTrace
	async updateFieldVisibility(key: string, value: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) {
		const state = value.detail.checked;
		if (this.eventUser().updatedSettings) {
			if (!this.eventUser().updatedSettings.fieldsVisibility) {
				// Retro-compatibility code
				try {
					this.eventUser().updatedSettings["fieldsVisibility"] = await this.SEventUsers.buildFieldsVisibility(
						this.event().uid,
						this.eventUser().moduleId,
						this.eventUser()
					);
				} catch (error) {
					console.error("updateFieldVisibility => error:", error);
				}
			}

			this.eventUser().updatedSettings.fieldsVisibility[key] = state;
			this.eventUser.set(_.cloneDeep(Object.assign({}, this.eventUser())));
		}
	}
}
