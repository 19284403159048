import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertsComponent } from "./alerts/alerts.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
// eslint-disable-next-line max-len
import { UserFieldsVisibilityManageComponent } from "src/app/content/pages/event/components/dumbs/user-fields-visbility-manage.component";

@NgModule({
	declarations: [AlertsComponent, UserFieldsVisibilityManageComponent],
	imports: [CommonModule, IonicModule, TranslateModule],
	exports: [AlertsComponent, UserFieldsVisibilityManageComponent]
})
export class GenericComponentsModule {}
