/* eslint-disable max-len */
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { limit, where } from "firebase/firestore";
import { combineLatest, map, of, skipWhile, switchMap, take } from "rxjs";
import { getBasicCustomPagesOfModule, getSpecificCustomPage } from "../selectors/custom-pages.selectors";
import { getAccessiblesTreasureHuntsOfModule } from "../selectors/gamification.selectors";
import { getLocations, getSpecificLocation } from "../selectors/generics-modules-data.selectors";
import {
	getAccessiblesAsksQuestionsByType,
	getAccessiblesExternalsInteractivityByType,
	getAccessiblesQuizsByType,
	getAccessiblesSurveysByType
} from "../selectors/interactivity.selectors";
import { getSpecificModule } from "../selectors/modules.selectors";
import { selectRouteNestedParams } from "../selectors/router.selectors";
import { checkIfDatasEventReady } from "../selectors/utility.selectors";
import {
	AskQuestionsService,
	CustomPagesService,
	EventUsersService,
	ExternalInteractivityService,
	LocationsService,
	ModulesManagerService,
	QuizsService,
	SheetsService,
	SurveysService,
	TreasureHuntsService
} from "../services";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import { FirestoreService } from "../services/firestore.service";
import { ISheet } from "../interfaces";
export const AskQuestionsGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SAsksQuestions = inject(AskQuestionsService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initAsksQuestions"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				SAsksQuestions.getAskQuestionsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initAsksQuestions"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						return store.select(getAccessiblesAsksQuestionsByType(-1, null)).pipe(
							take(1),
							switchMap((asksQuestions) => {
								if (
									asksQuestions.length > 1 ||
									(asksQuestions.length === 0 && !params["askQuestionsId"])
								) {
									return of(true);
								} else if (asksQuestions.length === 1) {
									if (params.askQuestionsId && params.askQuestionsId === asksQuestions[0].uid) {
										return of(true);
									} else if (!params.askQuestionsId && asksQuestions[0].visibility) {
										router.navigateByUrl(
											`event/${params.eventId}/asks-questions/${params.moduleId}/${asksQuestions[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								} else {
									return of(false);
								}
							})
						);
					})
				);
			} else {
				return store.select(getAccessiblesAsksQuestionsByType(-1, null)).pipe(
					take(1),
					switchMap((asksQuestions) => {
						if (asksQuestions.length > 1 || (asksQuestions.length === 0 && !params["askQuestionsId"])) {
							return of(true);
						} else if (asksQuestions.length === 1) {
							if (params.askQuestionsId && params.askQuestionsId === asksQuestions[0].uid) {
								return of(true);
							} else if (!params.askQuestionsId && asksQuestions[0].visibility) {
								router.navigateByUrl(
									`event/${params.eventId}/asks-questions/${params.moduleId}/${asksQuestions[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						} else {
							return of(false);
						}
					})
				);
			}
		})
	);
};

export const CustomPagesGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SCustomPages = inject(CustomPagesService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initCustomPages"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				SCustomPages.getCustomPagesOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initCustomPages"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						if (params.customPageId && params.customPageId !== "all") {
							return store.select(getSpecificCustomPage(params.customPageId)).pipe(
								take(1),
								switchMap((customPage) => {
									return customPage && customPage.uid === params.customPageId ? of(true) : of(false);
								})
							);
						} else {
							return store.select(getBasicCustomPagesOfModule(params.moduleId)).pipe(
								take(1),
								switchMap((customPages) => {
									if (customPages.length > 1 || customPages.length === 0) {
										return of(true);
									} else if (customPages.length === 1) {
										router.navigateByUrl(
											`event/${params.eventId}/custom-pages/${params.moduleId}/${customPages[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								})
							);
						}
					})
				);
			} else {
				if (params.customPageId && params.customPageId !== "all") {
					return store.select(getSpecificCustomPage(params.customPageId)).pipe(
						take(1),
						switchMap((customPage) => {
							return customPage && customPage.uid === params.customPageId ? of(true) : of(false);
						})
					);
				} else {
					return store.select(getBasicCustomPagesOfModule(params.moduleId)).pipe(
						take(1),
						switchMap((customPages) => {
							if (customPages.length > 1 || customPages.length === 0) {
								return of(true);
							} else if (customPages.length === 1) {
								router.navigateByUrl(
									`event/${params.eventId}/custom-pages/${params.moduleId}/${customPages[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						})
					);
				}
			}
		})
	);
};

export const EventUsersGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SEventUsers = inject(EventUsersService);
	return store.select(selectRouteNestedParams).pipe(
		take(1),
		switchMap((params) => {
			return store.select(getSpecificModule(params.moduleId)).pipe(
				skipWhile((module) => !module),
				take(1),
				switchMap((module) => {
					return of({
						params: params,
						module: module
					});
				})
			);
		}),
		switchMap((datas) => {
			const params = datas.params;
			const module = datas.module;
			return SEventUsers.getEventUsersWithConstraints(
				params.eventId,
				params.moduleId,
				module.options.showOnlyLoggedUsers ? [where("firstAccess", "==", true), limit(2)] : [limit(2)]
			).pipe(
				take(1),
				switchMap((eventUsers) => {
					if (eventUsers.length === 1) {
						router.navigateByUrl(`event/${params.eventId}/profile/${params.moduleId}/${eventUsers[0].uid}`);
						return of(false);
					} else {
						return of(true);
					}
				})
			);
		})
	);
};

export const ExternalInteractivitiesGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SExternalInteractivities = inject(ExternalInteractivityService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initExternalsInteractivity"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				SExternalInteractivities.getExternalInteractivityOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initExternalsInteractivity"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						return store.select(getAccessiblesExternalsInteractivityByType(0, null)).pipe(
							take(1),
							switchMap((externalInteractivities) => {
								if (
									externalInteractivities.length > 1 ||
									(externalInteractivities.length === 0 && !params["externalInteractivityId"])
								) {
									return of(true);
								} else if (externalInteractivities.length === 1) {
									if (
										params.externalInteractivityId &&
										params.externalInteractivityId === externalInteractivities[0].uid
									) {
										return of(true);
									} else if (
										!params.externalInteractivityId &&
										externalInteractivities[0].visibility
									) {
										router.navigateByUrl(
											`event/${params.eventId}/externals-interactivity/${params.moduleId}/${externalInteractivities[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								} else {
									return of(false);
								}
							})
						);
					})
				);
			} else {
				return store.select(getAccessiblesExternalsInteractivityByType(0, null)).pipe(
					take(1),
					switchMap((externalInteractivities) => {
						if (
							externalInteractivities.length > 1 ||
							(externalInteractivities.length === 0 && !params["externalInteractivityId"])
						) {
							return of(true);
						} else if (externalInteractivities.length === 1) {
							if (
								params.externalInteractivityId &&
								params.externalInteractivityId === externalInteractivities[0].uid
							) {
								return of(true);
							} else if (!params.externalInteractivityId && externalInteractivities[0].visibility) {
								router.navigateByUrl(
									`event/${params.eventId}/externals-interactivity/${params.moduleId}/${externalInteractivities[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						} else {
							return of(false);
						}
					})
				);
			}
		})
	);
};

export const QuizsGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SQuizs = inject(QuizsService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initQuizs"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				SQuizs.getQuizsOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initQuizs"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						return store.select(getAccessiblesQuizsByType(0, null)).pipe(
							take(1),
							switchMap((quizs) => {
								if (quizs.length > 1 || (quizs.length === 0 && !params["quizId"])) {
									return of(true);
								} else if (quizs.length === 1) {
									if (params.quizId && params.quizId === quizs[0].uid) {
										return of(true);
									} else if (!params.quizId && quizs[0].visibility) {
										router.navigateByUrl(
											`event/${params.eventId}/quizs/${params.moduleId}/${quizs[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								} else {
									return of(false);
								}
							})
						);
					})
				);
			} else {
				return store.select(getAccessiblesQuizsByType(0, null)).pipe(
					take(1),
					switchMap((quizs) => {
						if (quizs.length > 1 || (quizs.length === 0 && !params["quizId"])) {
							return of(true);
						} else if (quizs.length === 1) {
							if (params.quizId && params.quizId === quizs[0].uid) {
								return of(true);
							} else if (!params.quizId && quizs[0].visibility) {
								router.navigateByUrl(
									`event/${params.eventId}/quizs/${params.moduleId}/${quizs[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						} else {
							return of(false);
						}
					})
				);
			}
		})
	);
};

export const ModulesGuard = () => {
	const store = inject(Store);
	const SModules = inject(ModulesManagerService);
	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initModules"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			if (!results[1]) {
				SModules.getModulesOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initModules"])).pipe(
					skipWhile((init) => !init),
					take(1)
				);
			} else {
				return of(true);
			}
		})
	);
};

export const LocationsGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SLocations = inject(LocationsService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initLocations"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				SLocations.getLocationsForEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initLocations"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						if (params.locationId) {
							return store.select(getSpecificLocation(params.locationId)).pipe(
								take(1),
								switchMap((location) => {
									return location && location.uid === params.locationId ? of(true) : of(false);
								})
							);
						} else {
							return store.select(getLocations).pipe(
								take(1),
								switchMap((locations) => {
									if (locations.length > 1 || locations.length === 0) {
										return of(true);
									} else if (locations.length === 1) {
										router.navigateByUrl(
											`event/${params.eventId}/locations/${params.moduleId}/${locations[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								})
							);
						}
					})
				);
			} else {
				if (params.locationId) {
					return store.select(getSpecificLocation(params.locationId)).pipe(
						take(1),
						switchMap((location) => {
							return location && location.uid === params.locationId ? of(true) : of(false);
						})
					);
				} else {
					return store.select(getLocations).pipe(
						take(1),
						switchMap((locations) => {
							if (locations.length > 1 || locations.length === 0) {
								return of(true);
							} else if (locations.length === 1) {
								router.navigateByUrl(
									`event/${params.eventId}/locations/${params.moduleId}/${locations[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						})
					);
				}
			}
		})
	);
};

export const SheetsGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SFirestore = inject(FirestoreService);
	console.log("SheetsGuard");
	return combineLatest([store.select(selectRouteNestedParams), store.select(getCurrentEventUser)]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			return store.select(getSpecificModule(params.moduleId)).pipe(
				skipWhile((module) => !module),
				take(1),
				switchMap((module) => {
					return of({
						eventUser: results[1],
						params: params,
						module: module
					});
				})
			);
		}),
		switchMap((results) => {
			console.log("SheetsGuard switchMap: ", results);
			const params = results.params;
			const module = results.module;
			const eventUser = results.eventUser;
			const constraints = module.options.viewOnlyGroupsContent
				? [where("groups", "array-contains-any", eventUser.groups), limit(2)]
				: [limit(2)];
			return SFirestore.getDocumentsObs(
				`events/${params.eventId}/modules/${params.moduleId}/sheets`,
				constraints
			).pipe(
				take(1),
				map((datas) => datas.docs.map((doc) => doc.data() as ISheet)),
				switchMap((datas) => {
					console.log("SheetsGuard switchMap: ", constraints, datas);
					if (datas.length === 1) {
						router.navigateByUrl(`event/${params.eventId}/profile/${params.moduleId}/${datas[0].uid}`);
						return of(false);
					} else {
						return of(true);
					}
				})
			);
		})
	);
};

export const SurveysGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const SSurveys = inject(SurveysService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initSurveys"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				SSurveys.getSurveysOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initSurveys"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						return store.select(getAccessiblesSurveysByType(0, null)).pipe(
							take(1),
							switchMap((surveys) => {
								if (surveys.length > 1 || (surveys.length === 0 && !params["surveyId"])) {
									return of(true);
								} else if (surveys.length === 1) {
									if (params.surveyId && params.surveyId === surveys[0].uid) {
										return of(true);
									} else if (!params.surveyId && surveys[0].visibility) {
										router.navigateByUrl(
											`event/${params.eventId}/surveys/${params.moduleId}/${surveys[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								} else {
									return of(false);
								}
							})
						);
					})
				);
			} else {
				return store.select(getAccessiblesSurveysByType(0, null)).pipe(
					take(1),
					switchMap((surveys) => {
						if (surveys.length > 1 || (surveys.length === 0 && !params["surveyId"])) {
							return of(true);
						} else if (surveys.length === 1) {
							if (params.surveyId && params.surveyId === surveys[0].uid) {
								return of(true);
							} else if (!params.surveyId && surveys[0].visibility) {
								router.navigateByUrl(
									`event/${params.eventId}/surveys/${params.moduleId}/${surveys[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						} else {
							return of(false);
						}
					})
				);
			}
		})
	);
};

export const TreasureHuntsGuard = () => {
	const store = inject(Store);
	const router = inject(Router);
	const STreasureHunts = inject(TreasureHuntsService);

	return combineLatest([
		store.select(selectRouteNestedParams),
		store.select(checkIfDatasEventReady(["initTreasureHunts"]))
	]).pipe(
		take(1),
		switchMap((results) => {
			const params = results[0];
			if (!results[1]) {
				STreasureHunts.getTreasureHuntsOfEvent(results[0].eventId);
				STreasureHunts.getTreasureHuntsQrcodesOfEvent(results[0].eventId);
				return store.select(checkIfDatasEventReady(["initTreasureHunts"])).pipe(
					skipWhile((init) => !init),
					take(1),
					switchMap(() => {
						return store.select(getAccessiblesTreasureHuntsOfModule(params.moduleId)).pipe(
							take(1),
							switchMap((treasureHunts) => {
								if (
									treasureHunts.length > 1 ||
									(treasureHunts.length === 0 && !params["treasureHuntId"])
								) {
									return of(true);
								} else if (treasureHunts.length === 1) {
									if (params.treasureHuntId && params.treasureHuntId === treasureHunts[0].uid) {
										return of(true);
									} else if (!params.treasureHuntId && treasureHunts[0].visibility) {
										router.navigateByUrl(
											`event/${params.eventId}/treasure-hunts/${params.moduleId}/${treasureHunts[0].uid}`
										);
										return of(false);
									} else {
										return of(false);
									}
								} else {
									return of(false);
								}
							})
						);
					})
				);
			} else {
				return store.select(getAccessiblesTreasureHuntsOfModule(params.moduleId)).pipe(
					take(1),
					switchMap((treasureHunts) => {
						if (treasureHunts.length > 1 || (treasureHunts.length === 0 && !params["treasureHuntId"])) {
							return of(true);
						} else if (treasureHunts.length === 1) {
							if (params.treasureHuntId && params.treasureHuntId === treasureHunts[0].uid) {
								return of(true);
							} else if (!params.treasureHuntId && treasureHunts[0].visibility) {
								router.navigateByUrl(
									`event/${params.eventId}/treasure-hunts/${params.moduleId}/${treasureHunts[0].uid}`
								);
								return of(false);
							} else {
								return of(false);
							}
						} else {
							return of(false);
						}
					})
				);
			}
		})
	);
};
